<template>
  <section class="layout">
    <div class="menu-desktop">
      <MenuDesktop :menu="menuA" />      
    </div>
    <MenuMobile  :menu="menuA" />
    <div class="body">
      <Header ref="header" />
      <div class="main">
        <router-view />
      </div>
    </div>
    <div class="p-3 ml-5" data-fillout-id="dXpA7kxjMsus" data-fillout-embed-type="slider" data-fillout-button-text="Support" data-fillout-button-size="small" data-fillout-button-float="bottom-right" data-fillout-slider-direction="right" data-fillout-inherit-parameters></div>
  </section>  
</template>
<script>
import MenuDesktop from './MenuDesktop.vue';
import MenuMobile from './MenuMobile.vue';
import Header from './Header.vue';
export default {
  name: "",
  data(){
    return{      
      menu:[
        {
          "titre": "Agenda",
          "icon": "fas fa-calendar",
          "path": "/agenda/mois",
          "acl_id": 1
        }, 
        {
          "titre": "Utilisateur",
          "icon": "fas fa-user",
          "path": "/utilisateur/liste",
          "acl_id": 2
        }, 
        {
          "titre": "Prospect",
          "icon": "fas fa-gem",
          "path": "/prospect/liste",
          "acl_id": 3
        }, 
        {
          "titre": "Devis",
          "icon": "fas fa-notes-medical",
          "path": "/devis/liste",
          "acl_id": 4
        }, 
        {
          "titre": "SMS",
          "icon": "fas fa-comments",
          "path": "/sms/liste",
          "acl_id": 18
        }, 
        {
            "titre": "Produit mutuelle",
            "icon": "fas fa-cog",
            "path": "/produit/liste",
            "acl_id": 5
        }, 
        {
            "titre": "Leads",
            "icon": "fas fa-users",
            "path": "/source/liste",
            "acl_id": 26
        }, 
        {
          "titre": "Serveur Paris",
          "icon": "fas fa-laptop",
          "path": "/utilisateur/liste",
          "acl_id": 1
        },                    
        {
          "titre": "VPN Paris",
          "icon": "fas fa-network-wired",
          "path": "/utilisateur/vpn",
          "acl_id": 24
        },                    
        {
          "titre": "Distribution",
          "icon": "fas fa-share-alt",
          "path": "/distribution",
          "acl_id": 27
        },                            
        {
          "titre": "WhatsApp",
          "icon": "fab fa-whatsapp",
          "path": "/whatsapp",
          "acl_id": 29
        },                            
      ]        
    }
  },
  components: {
    MenuDesktop, MenuMobile, Header
  },  
  mounted(){
    //SocketioService.
  },
  computed:{
    menuA(){
      return this.menu.filter(m => this.$api.acl(m.acl_id));
    }
  },  
  beforeCreate() {    
  },  
  methods:{    
  }
}
</script>
<style lang="scss">
</style> 